import { Component, OnInit } from '@angular/core';
import { MessagingService } from './messaging.service';
import { AppService } from './app.service';
import { ToasterConfig } from 'angular2-toaster';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'app';
  fullname;
  _opened = false;
  toastConfig: ToasterConfig =
    new ToasterConfig({
      animation: 'slideDown',
      positionClass: 'toast-bottom-full-width',
      // showCloseButton: true,
      tapToDismiss: true,
      timeout: 10000
    });

  constructor(
    private messagingService: MessagingService,
    private appService: AppService,
    private jwtHelper: JwtHelperService,
    private router: Router,
  ) {
    this.messagingService.currentMessage
      .subscribe(message => {
        if (message === 'opensidebar') {
          if (!this._opened) {
            this._opened = true;
          }
        }
        if (message === 'closesidebar') {
          if (this._opened) {
            this._opened = false;
          }
        }
        if (message === 'loginsuccess') {
          this.userData();
        }
      });
  }

  ngOnInit() {
    if (this.loggedin()) {
      this.userData();
    }
  }

  userData() {
    const _profile = localStorage.getItem('access_token');
    const profile = this.jwtHelper.decodeToken(_profile);
    this.fullname = profile.fullname;
  }

  loggedin() {
    return this.appService.loggedin();
  }

  login() {
    this.router.navigateByUrl('/auth/login');
  }

  logout() {
    this.appService.logout();
  }

  _toggleSidebar() {
    this._opened = !this._opened;
  }

  brandlink() {
    return this.appService.loggedin() ? '/user/webtop' : '/';
  }

  brand() {
    return this.appService.loggedin() ? this.fullname : 'Κέντρο Ηλεκτρονικών Υπολογιστών ΕΜΠ';
  }
}
