import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { JwtModule, JwtHelperService } from '@auth0/angular-jwt';
import { ToasterModule, ToasterService } from 'angular2-toaster';
import { MarkdownModule, MarkedOptions, MarkedRenderer } from 'ngx-markdown';
import { HighlightModule } from 'ngx-highlightjs';

import { SidebarModule } from 'ng-sidebar';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { SidebarComponent } from './sidebar/sidebar.component';

import { AppService } from './app.service';
import { MessagingService } from './messaging.service';

import { ErrorInterceptor } from './error.interceptor';
import { ResponseInterceptor } from './response.interceptor';

export function tokenGetterFunc() {
  return localStorage.getItem('access_token');
}

export function markedOptionsFactory(): MarkedOptions {
  const renderer = new MarkedRenderer();

  renderer.heading = (text, level) => {
    const idtext = text.toLowerCase().replace(/\s/g, '-');
    return '<h' + level + ' id="' + idtext + '">' + text + '</h' + level + '>';
  };

  // renderer.link = (href, title, text) => {
  //   return '<a routerLink="' + href + '">' + text + '</a>';
  // };

  // renderer.code = (code, language) => {
  //   return '<div highlight="all" innerHtml="' + code + '"></div>'
  // }

  return {
    renderer: renderer,
    gfm: true,
    tables: true,
    breaks: true,
    pedantic: false,
    sanitize: true,
    smartLists: true,
    smartypants: false,
  };
}

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgbModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetterFunc,
        whitelistedDomains: ['backend.central.ntua.gr', '147.102.246.132:1337']
      }
    }),
    SidebarModule.forRoot(),
    ToasterModule.forRoot(),
    MarkdownModule.forRoot({
      loader: HttpClient,
      markedOptions: {
        provide: MarkedOptions,
        useFactory: markedOptionsFactory,
      },
    }),
    HighlightModule.forRoot({ theme: 'agate' })
  ],
  providers: [
    // { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    MessagingService,
    AppService,
    JwtHelperService,
    ToasterService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
