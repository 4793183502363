import { Injectable } from '@angular/core';
// import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class MessagingService {

  private messageSource = new BehaviorSubject<string>('default message');
  currentMessage = this.messageSource;

  constructor() { }

  sendMessage(message: string) {
    this.messageSource.next(message);
  }
}
