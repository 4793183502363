import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

import { MessagingService } from '../messaging.service';
import { AppService } from '../app.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  private message: string;
  private fullname;
  private username;
  private roles;
  private certRole;
  private rolear = [];

  constructor(
    private messagingService: MessagingService,
    private appService: AppService,
    private jwtHelper: JwtHelperService,
    private router: Router,
  ) {
    this.messagingService.currentMessage
      .subscribe(message => {
        if (message === 'loginsuccess') {
          this.userData();
        }
      });
  }

  ngOnInit() {
    if (this.loggedin()) {
      this.userData();
    }
  }

  userData() {
    const _profile = localStorage.getItem('access_token');
    const profile = this.jwtHelper.decodeToken(_profile);
    this.fullname = profile.fullname;
    this.username = profile.username;
    this.roles = profile.roles;
    if (this.roles) this.getRoles();
    this.certRole = this.rolear.includes('certificates') ? true : false;
  }

  loggedin() {
    return this.appService.loggedin();
  }

  logout() {
    this.appService.logout();
  }

  closeSidebar() {
    this.messagingService.sendMessage('closesidebar');
  }

  getRoles() {
    for (const r of this.roles) {
      this.rolear.push(r.roleid);
    }
  }

}
