import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: 'static', loadChildren: './static/static.module#StaticModule' },
  { path: 'wiki', loadChildren: './wiki/wiki.module#WikiModule' },
  { path: 'auth', loadChildren: './auth/auth.module#AuthModule' },
  { path: 'admin', loadChildren: './admin/admin.module#AdminModule' },
  { path: 'user', loadChildren: './user/user.module#UserModule' },
  { path: 'studdata', loadChildren: './studdata/studdata.module#StuddataModule' },
  { path: 'registrations', loadChildren: './registrations/registrations.module#RegistrationsModule' },
  { path: '', pathMatch: 'full', redirectTo: '/static/frontpage' },
  { path: '**', pathMatch: 'full', redirectTo: '/static/frontpage' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
