import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { HttpRequest, HttpResponse, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { ToasterService } from 'angular2-toaster';
import { AppService } from './app.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private appService: AppService,
    private toasterService: ToasterService,
    private router: Router,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError(err => {
          if (err.status === 401) {
            // auto logout if 401 response returned from api
            this.toasterService.pop('error', 'Έλεγχος πρόσβασης', 'Η πρόσβαση δεν επιτρέπεται! Λάθος όνομα χρήστη ή/και λάθος στο μυστικό κωδικό.');
            this.appService.logout();
            this.router.navigateByUrl('/static/assistant/loginerror');
            // location.reload(true);
          }
          const error = err.error.message || err.statusText;
          return throwError(error);
        }))
  }
}
