import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class AppService {

  constructor(
    private http: HttpClient,
    private router: Router,
    private jwtHelper: JwtHelperService
  ) { }

  // CertificatesService

  createCertificate(authorizations, certificate) {
    return this.http
      .post('https://backend.central.ntua.gr/certificate/create', {
        authorizations: authorizations,
        certificate: certificate
      });
  }

  updateCertificate(authorizations, certificate) {
    return this.http
      .post('https://backend.central.ntua.gr/certificate/update', {
        authorizations: authorizations,
        certificate: certificate
      });
  }

  getAllCertificates() {
    return this.http
      .get('https://backend.central.ntua.gr/certificate/getAll');
  }

  getAllApplications(department, category) {
    return this.http
      // .post('https://backend.central.ntua.gr/profile/username', {
      .post('https://backend.central.ntua.gr/api/certificates/user/all', {
        department: department,
        category: category
      });
  }

  // getAllApplicationsByStatus(authorizations, status, skip) {
  getAllApplicationsByStatus(authorizations, status, skip) {
    return this.http
      .post('https://backend.central.ntua.gr/certificateApplication/getApplicationsByStatus', {
        authorizations: authorizations,
        status: status,
        skip: skip
      });
  }

  setApplicationsStatus(authorizations, applications, status) {
    return this.http
      .post('https://backend.central.ntua.gr/certificateApplication/setApplicationsStatus', {
        authorizations: authorizations,
        applications: applications,
        status: status,
      });
  }

  getUserApplications(username) {
    return this.http
      .post('https://backend.central.ntua.gr/certificateApplication/getApplications', {
        username: username
      });
  }

  createUserApplications(applications) {
    return this.http
      .post('https://backend.central.ntua.gr/certificateApplication/createApplications', {
        applications: applications
      });
  }

  getAllDepartmentCertificatesEnabled(user) {
    const department = user['department'];
    const category = user['category'];
    return this.http
      .post('https://backend.central.ntua.gr/certificate/getAllEnabled', {
        department: department,
        category: category
      });
  }

  countApplicationsByStatus(authorizations, status) {
    return this.http
      .post('https://backend.central.ntua.gr/certificateApplication/countApplicationsByStatus', {
        authorizations: authorizations,
        status: status,
      });
  }

  getpermissions(roleid) {
    if (localStorage.getItem('profile')) {
      const tmp = JSON.parse(localStorage.getItem('profile')).roles;
      return tmp ? tmp.find(x => x.roleid === roleid) : false;
    }
  }

  getAuthorizations(roleid) {
    const permissions = this.getpermissions(roleid);
    const authorizations = [];
    for (const authorization of permissions.authorizations) {
      authorizations.push({ 'department.id': authorization.department, 'category.id': authorization.category });
    }
    return authorizations;
  }

  // UserService

  getProfile() {
    return this.http
      .get('https://backend.central.ntua.gr/profile/get');
  }

  profile() {
    return JSON.parse(localStorage.getItem('profile'));
  }

  loggedin() {
    return localStorage.getItem('access_token') ? !this.jwtHelper.isTokenExpired() : false;
  }

  logout() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('profile');
    localStorage.removeItem('central');
    localStorage.removeItem('enabledRegistrations');
    localStorage.removeItem('allStudents');
    localStorage.removeItem('acceptance');
    this.router.navigateByUrl('/');
  }

  // AuthService

  login(credentials) {
    return this.http
      .post('https://backend.central.ntua.gr/user/login', {
        username: credentials.username,
        password: credentials.password
      });
  }

  lookup(userinfo) {
    return this.http
      .post('https://backend.central.ntua.gr/user/forgot-password', userinfo);
  }

  tokenisValid(token) {
    return this.http.post('https://backend.central.ntua.gr/user/validate-reset-token', {
      token: token
    });
  }

  reset(data) {
    return this.http
      .post('https://backend.central.ntua.gr/user/reset-password', data);
  }

  changepassword(data) {
    return this.http
      .post('https://backend.central.ntua.gr/user/change-password', data);
  }

  queryTelephoneDirectory(data) {
    return this.http
      .post('https://backend.central.ntua.gr/user/query-telephone-dir', data);
  }

  changeEmail(data) {
    return this.http
      .post('https://backend.central.ntua.gr/user/change-email', data);
  }

  // Registrations Module

  // Επιστρέφει όλες τις περιόδους εγγραφών για συγκεκριμένη σχολή και κατηγορία
  // Used in Registrations-Grads-Admin-ListRegistrationComponent
  // Used in Registrations-Grads-Admin-ListStudentRegistrationComponent
  getAllRegistrations(department, category) {
    return this.http
      .post('https://backend.central.ntua.gr/registrations/get-all-registrations', {
        department: department,
        category: category
      });
  }

  // Επιστρέφει όλα τα προγραμμάτα σπουδων της σχολής, δηλαδή το ακαδημαικό έτος και περίοδο (χειμερινό, εαρινό)
  // Used in Registrations-Grads-EditRegistrationComponent
  // Used in Registrations-Grads-EditStudentsComponent
  getPeriod(department, category) {
    return this.http
      .post('https://backend.central.ntua.gr/registrations/get-period', {
        department: department,
        category: category
      });
  }

  // Επιστρέφει για συγκεκριμένο έτος και περίοδο (χειμερινό, εαρινό) τα μαθήματα
  // Used in Registrations-Grads-EditRegistrationComponent
  // Used in Registrations-Grads-EditStudentsComponent
  getLessons(year, semester, department, category) {
    return this.http
      .post('https://backend.central.ntua.gr/registrations/get-lessons', {
        year: year,
        semester: semester,
        department: department,
        category: category
      });
  }

  // Εκχωρεί μια νέα εγγραφή απο τη γραμματεία
  // Used in Registrations-Grads-EditRegistrationComponent
  setRegistration(data, createoredit) {
    return this.http
      .post('https://backend.central.ntua.gr/registrations/set-registration', {
        registration: data,
        createoredit: createoredit
      });
  }

  // Επιστρέφει όλους τους φοιτητές συγκεκριμένης σχολής και κατηγορίας
  // Used in Registrations-Grads-ListStudentsComponent
  getAllStudents(type, department, subcategory) {
    return this.http
      .post('https://backend.central.ntua.gr/profile/get-all-students', {
        type: type,
        department: department,
        subcategory: subcategory
      });
  }

  // Used in Registrations-Grads-ListStudentsComponent
  setStudentProgress(uncheckedusers, checkedusers, department, subcategory, log) {
    return this.http
      .post('https://backend.central.ntua.gr/profile/set-student-progress', {
        uncheckedusers: uncheckedusers,
        checkedusers: checkedusers,
        department: department,
        subcategory: subcategory,
        log: log
      });
  }

  // Used in Registrations-Grads-Admin-EditStudentsComponent
  setProfile(username, query) {
    return this.http
      .post('https://backend.central.ntua.gr/profile/set-profile', {
        username: username,
        query: query
      });
  }

  // Used in Registrations-Grads-ListStudentRegistrationComponent
  getLessonsCount(registrationid) {
    return this.http
      .post('https://backend.central.ntua.gr/profile/get-lessons-count', {
        registrationid: registrationid
      });
  }

  // Εγκρίνει τις εγγραφές των φοιτητών
  // Used in Registrations-Grads-EditStudentRegistrationComponent
  setRegisteredStudents(usernames, log, registrationid, form_name) {
    return this.http
      .post('https://backend.central.ntua.gr/profile/set-registered-students', {
        usernames: usernames,
        log: log,
        registrationid: registrationid,
        form_name: form_name
      });
  }

  // Επιστρέφει όλες τις εγγραφές και δηλώσεις των φοιτητών
  // Used in Registrations-Grads-EditStudentRegistrationComponent
  getRegisteredStudents(id) {
    return this.http
      .post('https://backend.central.ntua.gr/profile/get-registered-students', {
        registrationid: id
      });
  }

  // Επιστρέφει όλες τις ενεργές εγγραφές συγκεκριμένου τμήματος και κατηγορίας
  // Used in Registrations-Users-ListRegistrationComponent
  // Used in Registrations-Professor-RGPListStudentsComponent
  getEnabledRegistration(department, category, fromWho) {
    return this.http
      .post('https://backend.central.ntua.gr/registrations/get-enabled-registration', {
        department: department,
        category: category,
        fromWho: fromWho
      });
  }

  // Used in Registrations-Test-TestListRegistrationComponent
  // Used in Registrations-Professor-ProfessorsComponent
  getUserProfile(studentcode) {
    return this.http
      .post('https://backend.central.ntua.gr/profile/get-profile', {
        studentcode: studentcode,
      });
  }

  async licensesCurrUsage() {
    const resp = await this.http
      .get('https://backend.central.ntua.gr/licenses/show-currusage')
      .toPromise();
    return resp;
  }

  // Used in Studdata-Admin-StuddataAdminComponent
  getUserProfileByUsernameOrID(department, category, student) {
    return this.http
      .post('https://backend.central.ntua.gr/profile/get-profile-by-username-or-id', {
        department: department,
        category: category,
        student: student
      });
  }

  // Used in Registrations-Professor-RGPListStudentsComponent
  getUsernamesForAcceptance(usernames) {
    return this.http
      .post('https://backend.central.ntua.gr/profile/get-usernames-for-acceptance', {
        usernames: usernames
      });
  }

  // Used in Registrations-Professor-RGPEditStudentsComponent
  setAcceptedLessons(username, regid, lessonCodes, log) {
    return this.http
      .post('https://backend.central.ntua.gr/profile/set-accepted-lessons', {
        username: username,
        regid: regid,
        lessonCodes: lessonCodes,
        log: log
      });
  }

  // Used in Lessons Component
  getAllSelectedLessons(username, regid) {
    return this.http
      .post('https://backend.central.ntua.gr/registrations/get-selected-lessons', { username: username, regid: regid })
  }
}
